import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { getCurrentUser, setUser, updateUser } from '../utils/auth'
import {
  teamUpdateName,
  isTeamCaptain,
  getCurrentTeamData,
  hasTeamCaptain,
  updateTeamCaptain,
  getTeamCaptain,
} from '../utils/team-data'
import { navigate } from 'gatsby'
import { getSiteData } from '../utils/site'
import FloatingInput from '../components/floating-input'
import LoggedInWrapper from '../components/loggedinWrapper'
import AppContext from '../utils/context'
import SVGLoader from '../components/svg/loader'

/**
 * Choose a Captain Page
 * 
 * Settings page where the team learns about what the team captain's
 * role is and selects a team captain. The captain then gets the
 * chance to choose a team name.
 * 
 * After this page, users go to the Rules Page.
 */

const CaptainPage = () => {
  const context = useContext(AppContext)
  const [userData, setUserData] = useState({
    guid: ``,
    name: ``,
    email: ``,
    teamName: ``,
    teamCaptain: false,
    has_captain: false,
    player_count: 0,
  })
  const [modalCaptainShow, setModalCaptainShow] = useState(false)
  const [settingCaptain, setSettingCaptain] = useState(false)
  const [site_data, setSiteData] = useState({})

  // initialize all the data
  useEffect(() => {
    const current_user = getCurrentUser()
    const team_data = getCurrentTeamData()
    setUserData({
      guid: current_user.guid,
      name: current_user.name ? current_user.name : ``,
      email: current_user.email ? current_user.email : ``,
      teamName: team_data.team_name ? team_data.team_name : ``,
      player_count: team_data.player_count ? team_data.player_count : ``,
      teamCaptain: isTeamCaptain(),
      has_captain: hasTeamCaptain(),
    })
    getSiteData().then(data => {
      setSiteData(data)
      context.setLoading(false)
    })
  }, [])

  // not the team captain, just go to the rules page
  const handleSubmit = (event) => {
    event.preventDefault()
    context.setLoading(true)
    navigate(`/rules/`)
  }

  // update the team name
  const handleTeamNameSubmit = (event) => {
    event.preventDefault()
    if (isTeamCaptain()) {
      context.setLoading(true)
      console.log(`updating team name`)
      teamUpdateName(userData.teamName, userData.player_count)
      //this.setState({ alertShow: true, alertType: 'success', alertMsg: 'Settings saved!' })
      navigate(`/rules/`)
    }
  }

  // show the confirm captain role modal
  const confirmCaptain = (event) => {
    event.preventDefault()
    setModalCaptainShow(true)
  }

  // update the team captain to the current user
  const becomeTeamCaptain = () => {
    updateTeamCaptain(userData.guid, true)
  }

  // "yes I'm the captain" callback
  const handleTeamCaptain = (event) => {
    setSettingCaptain(true)
    event.preventDefault()
    // update the team captain to the current user, then show the team name section
    updateTeamCaptain(userData.guid).then(response => {
      // console.log(response)
      if (response.status == `error`) {
        // there was an error setting the team captain, maybe someone else already was the captain?
        // console.log(`error setting team captain`)
        // console.log(response.data)
        setSettingCaptain(false)
        setModalCaptainShow(false)
        setUserData({ ...userData, has_captain: hasTeamCaptain() })
        context.showAlert(`Error setting the team captain!`)
      } else {
        // successfully became the captain
        setSettingCaptain(false)
        setModalCaptainShow(false)
        setUserData({ ...userData, has_captain: hasTeamCaptain(), teamCaptain: true })
        context.showAlert(`Settings saved!`)
      }
    })
  }

  // get the current team captain
  const captain = getTeamCaptain()

  return (
    <LoggedInWrapper>
      <div className={`mx-4 mt-3 mb-3`}>
        <div className={`card full-height`}>
          <div className="card-header text-center">
            <h1 className="h3 mb-0">{site_data.how_to_play_heading}</h1>
          </div>
          <div className="card-body pb-3 justify-content-between">
            {userData.teamCaptain ? (
              <>
                <div>
                  <h4 className={`subtitle h3 text-uppercase`}>Hi there, Captain!</h4>
                  <p className={`px-3`}>
                    Congratulations on taking all the responsibility. If you need to transfer captain-ship at any point
                    during the game, click on the <FontAwesomeIcon icon={faCog} /> settings icon above.
                  </p>
                </div>
                <div>
                  <h4 className={`subtitle h3 text-uppercase mt-5`}>What&apos;s your team name?</h4>
                  <p className={`px-3`}>
                    If you can&apos;t come up with something amazing right now, that&apos;s okay... you can always
                    change it later in your settings.
                  </p>
                  <form method="post" onSubmit={handleTeamNameSubmit}>
                    <FloatingInput
                      name={`teamName`}
                      placeholder={`Enter Team Name`}
                      onChange={e => setUserData({ ...userData, teamName: e.target.value })}
                      value={userData.teamName}
                      disabled={!userData.teamCaptain}
                    />

                    <h4 className={`subtitle h3 text-uppercase mt-5`}>How many players are on your team?</h4>
                    <p className={`px-3`}>Include yourself in the total.</p>
                    <FloatingInput
                      name={`teamName`}
                      placeholder={`Player Count (required)`}
                      onChange={e => setUserData({ ...userData, player_count: e.target.value })}
                      value={userData.player_count}
                      disabled={!userData.teamCaptain}
                    />

                    <button
                      className={`btn btn-primary btn-block btn-arrow mt-3 mb-3`}
                      type="submit"
                      disabled={!userData.player_count}>
                      Continue
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <>
                <div>
                  <h4 className={`subtitle h3 text-uppercase`}>Pick a Team Captain</h4>
                  <p className={`px-3 mb-5`}>{site_data.team_captain_text}</p>
                </div>
                <form
                  className={`form`}
                  method="post"
                  onSubmit={event => {
                    handleSubmit(event)
                  }}>
                  {userData.has_captain ? (
                    <div className={`form-group mt-auto`}>
                      {captain.name !== undefined && captain.name !== `` ? (
                        <p>{captain.name} is already the team captain.</p>
                      ) : (
                        <p>Someone is already the team captain.</p>
                      )}
                      <button
                        className={`btn btn-block btn-primary btn-arrow`}
                        onClick={event => {
                          handleSubmit(event)
                        }}>
                        Continue
                      </button>
                    </div>
                  ) : (
                    <div className={`mt-auto mb-3`}>
                      <h3 className="subtitle h3 text-uppercase mb-3">
                        Are you the <br />
                        team captain?
                      </h3>
                      <div className="px-3">
                        <button className={`btn btn-block btn-primary mb-3`} onClick={confirmCaptain}>
                          You betcha!
                        </button>
                        <button className={`btn btn-block btn-tan`} onClick={handleSubmit}>
                          Nope
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={modalCaptainShow}
        onHide={() => setModalCaptainShow(false)}
        centered
        aria-labelledby="captain-modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="captain-modal-title">
            Are you sure <br /> you want to be <br /> the captain?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`mt-5`}>
            {settingCaptain ? (
              <div className={`text-center`}>
                <SVGLoader />
              </div>
            ) : (
              <>
                <button className={`btn btn-block btn-success mb-3`} onClick={handleTeamCaptain}>
                  Yes!
                </button>
                <button className={`btn btn-block btn-tan`} onClick={() => setModalCaptainShow(false)}>
                  Cancel
                </button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </LoggedInWrapper>
  )
}

export default CaptainPage